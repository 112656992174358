import type { ParsedUrlQuery } from 'querystring';

import type { Account, Blank, CartCard, ContentCard, Drop, Product, ProductCard, PurchaseCard, RegisterCard, SanityKeyed, ShippingCard, SubscriberList } from '@drop-party/sanity';
import { isString } from 'lodash/fp';
import type { GetStaticPaths, GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import React from 'react';
import type { FunctionComponent } from 'react';
import HttpError from 'standard-http-error';

import { ConnectedDropPage as DropPage } from 'components/DropPage';
import type { ConnectedProps as DropPageProps } from 'components/DropPage';
import { getDrop } from 'pages/api/v1/drops/[drop]';
import sanity from 'vendors/sanity';
import { imageBuilder, removeNulls } from 'vendors/sanity/utils';
import { captureException } from 'vendors/sentry/utils';

interface Params extends ParsedUrlQuery {
	domain: string;
	drop: string;
}

interface Props {
	preview: boolean;
	products: DropPageProps['products'];
	drop: Pick<Drop, 'label' | 'ogImage'> & DropPageProps['drop'] & {
		cards: SanityKeyed<(
			| CartCard
			| ContentCard
			| PurchaseCard
			| ShippingCard
			| Omit<ProductCard, 'product'> & {
				product: Omit<Product, 'blank'> & {
					blank?: Blank;
				};
			}
			| Omit<RegisterCard, 'subscriberList'> & {
				subscriberList: Pick<SubscriberList, '_id'>;
			}
		)>[];
	};
}

export const getStaticPaths: GetStaticPaths<Params> = async () => {
	try {
		type DropQueried = Pick<Drop, 'slug'> & { account: Required<Pick<Account, 'domain'>> };

		return {
			fallback: 'blocking',
			paths:    removeNulls(await sanity({}).fetch<DropQueried[]>(`
				*[_type == "drop" && defined(slug.current) && defined(account->domain) && !isExternal] {
					slug,
					account -> {
						domain
					},
				}
			`))
				.map(({ account: { domain }, slug: { current: drop } }) => ({
					params: {
						domain,
						drop,
					},
				})),
		};
	} catch (err) {
		await captureException(err);

		throw err;
	}
};

export const getStaticProps: GetStaticProps<Props, Params> = async ({ params, preview }) => {
	try {
		if (!params?.drop || typeof params.drop !== 'string') {
			throw new HttpError(HttpError.BAD_REQUEST, 'Missing Drop', { params });
		}

		if (!params.domain || typeof params.domain !== 'string') {
			throw new HttpError(HttpError.BAD_REQUEST, 'Missing Domain', { params });
		}

		const { drop, products } = await getDrop({
			...params,
			as: 'slug',
		}, preview);

		return {
			revalidate: 60,
			props:      {
				drop,
				products,
				preview: Boolean(preview),
			},
		};
	} catch (err) {
		if (err instanceof HttpError && err.code === HttpError.NOT_FOUND) {
			return { notFound: true };
		}

		if (!(err instanceof HttpError) || err.code < 400 || err.code >= 500) {
			await captureException(err);
		}

		throw err;
	}
};

export const RoutedDropPage: FunctionComponent<Props> = ({
	drop,
	preview,
	products,
	drop: {
		label,
		ogImage,
	},
}) => {
	const { isReady, query: { cardset, ticket } } = useRouter();

	return (
		<>
			<NextSeo
				description={ogImage?.alt ?? label}
				title={label}
				twitter={{ cardType: 'summary_large_image' }}
				openGraph={{
					type:   'website',
					images: !ogImage
						? []
						: [
							{
								alt:    ogImage.alt,
								height: 1200,
								width:  630,
								url:    imageBuilder.image(ogImage)
									.size(1200, 630)
									.url()!,
							},
						],
				}}
			/>

			<DropPage
				cardset={!preview ? undefined : cardset as string}
				drop={drop}
				products={products}
				ticket={!isReady ? undefined : !isString(ticket) ? null : ticket}
			/>
		</>
	);
};

export default RoutedDropPage;
